/*** IMPORTS FROM imports-loader ***/
var define = false;

/**
 * ########################################
 * Global Ligthbox Options
 * ########################################
 */
import lightbox from 'lightbox2/dist/js/lightbox';

jQuery(document).ready(function($) {

    lightbox.option({
        alwaysShowNavOnTouchDevices: true,
        albumLabel: "",
        disableScrolling: true,
        fadeDuration: 500,
        fitImagesInViewport: true,
        imageFadeDuration: 300,
        resizeDuration: 300,
        showImageNumberLabel: true,
        wrapAround: true,
        positionFromTop: 150,
        maxWidth: 1200,
    });

});

