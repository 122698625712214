/*** IMPORTS FROM imports-loader ***/
var define = false;

/**
 * ########################################
 * Fallback for img object-fit for Older Browsers
 * ########################################
 */
jQuery(document).ready(function($) {

    function tkObjectFit() {
        $('img.object-fit').each(function() {
            var imgSrc = $(this).attr('src');
            var fitType = 'cover';

            if($(this).data('fit-type')) {
                fitType = $(this).data('fit-type');
            }

            $(this).parent().css({ 'background' : 'transparent url("'+imgSrc+'") no-repeat center center/'+fitType, });
            $(this).remove();
        });
    }

    if(!Modernizr.objectfit) {
        tkObjectFit();
    }

});

