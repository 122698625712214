/*** IMPORTS FROM imports-loader ***/
var define = false;

// Packages
import 'bootstrap';

// Frameworks
import './frameworks/modernizr-object-fit.js'; import './frameworks/particles.js';

// Includes
import './includes/lightbox.js'; import './includes/menu.js'; import './includes/object-fit.js';

// Components
;


particlesJS("particles-js", {
particles: {
  number: { value: 75, density: { enable: true, value_area: 800 } },
  color: { value: "#ffffff" },
  shape: {
    type: "circle",
    stroke: { width: 0, color: "#000000" },
    polygon: { nb_sides: 5 },
    image: { src: "", width: 100, height: 100 }
  },
  opacity: {
    value: 0.3,
    random: true,
    anim: { enable: true, speed: 1, opacity_min: 0, sync: false }
  },
  size: {
    value: 4,
    random: true,
    anim: { enable: false, speed: 4, size_min: 0.3, sync: false }
  },
  line_linked: {
    enable: false,
    distance: 150,
    color: "#ffffff",
    opacity: 0.2,
    width: 1
  },
  move: {
    enable: true,
    speed: 1,
    direction: "none",
    random: true,
    straight: false,
    out_mode: "out",
    bounce: false,
    attract: { enable: false, rotateX: 300, rotateY: 300 }
  }
},
interactivity: {
  detect_on: "window",
  events: {
    onhover: { enable: true, mode: "bubble" },
    onclick: { enable: true, mode: "repulse" },
    resize: true
  },
  modes: {
    grab: { distance: 400, line_linked: { opacity: 1 } },
    bubble: { distance: 250, size: 0, duration: 2, opacity: 0, speed: 3 },
    repulse: { distance: 400, duration: 0.4 },
    push: { particles_nb: 4 },
    remove: { particles_nb: 2 }
  }
},
retina_detect: true
});

