/*** IMPORTS FROM imports-loader ***/
var define = false;

/**
 * ########################################
 * Hamburger Animation
 * ########################################
 */
jQuery(document).ready(function($) {

    $('.hamburger').click(function(){
        $(this).toggleClass('is-active');
        $('#menu-sidebar').toggleClass('toggled');
        $('#site-header').toggleClass('extended');
        $('#logo').toggleClass('toggled');
        $('body').toggleClass('modal-open');
        $('#circle').toggleClass('inverted');
    });

    if ($('#main-wrapper').find('.hero').length && $('.hero').is(":first-child")) {
        $(window).scroll(function () {
          if ($(document).scrollTop() < 150) {
              $('#site-header').removeClass('scrolled');
          } else {
              $('#site-header').addClass('scrolled');
          }
        });
    } else {
        $('#site-header').addClass('scrolled');
        $('#main-wrapper').addClass('no-hero');
    }

});




/**
 * ########################################
 * Close Navbar when Clicking Anywhere
 * ########################################
 */
jQuery(document).ready(function($) {

    $(document).click(function (event) {
        var click = $(event.target);
        var _open = $(".navbar-collapse").hasClass("show");
        if (_open === true && !click.hasClass("navbar-toggler")) {
            $(".navbar-toggler").click();
        }
    });

});

